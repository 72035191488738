<template>
  <div>
    <lz-list
      :config="config"
      :table="table"
      :search="search"
      ref="lzList"
      @search-btn-click="handleCommonBtnCLick"
      @table-btn-click="handleTableBtnClick">
    </lz-list>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      class="dialogEditor"
      :title="title"
      ref="lzDialog">
      <lz-form :form="form" :col="3" ref="lzForm"></lz-form>
    </lz-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search: [
        { name: '文章名称', type: 'text', fieldName: 'keyword', value: '' },
      ],
      config: {
        url: '/help/article/list',
      },
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '标题', prop: 'title' },
        // { name: '副标题', prop: 'subtitle' },
        // { name: '分类名称', prop: 'class_name' },
        {
          name: '是否显示',
          func: (ops) => (ops.status ? '否' : '是'),
        },
        { name: '创建时间', prop: 'create_time' },
        { name: '操作', prop: 'htmlButton' },
      ],
      form: [
        { fieldName: 'title', type: 'text', name: '标题', value: '', required: true },
        // { fieldName: 'subtitle', type: 'text', name: '副标题', value: '', required: false },
        // {
        //   fieldName: 'class_id',
        //   type: 'treeSelect',
        //   name: '分类',
        //   value: '',
        //   options: [],
        //   required: true,
        //   remote: true,
        //   props: {
        //     url: '/article-class/list',
        //     value: 'id',
        //     label: 'name',
        //   },
        // },
        {
          fieldName: 'status',
          type: 'select',
          name: '状态',
          required: true,
          value: '',
          options: [
            { label: '启用', value: 0 },
            { label: '禁用', value: 1 },
          ],
        },
        { fieldName: 'sort', type: 'number', name: '排序', value: '', required: false },
        // {
        //   fieldName: 'attachment_ids',
        //   responseName: 'attachment',
        //   type: 'upload',
        //   name: '图片(应用场景440 * 800)',
        //   value: [],
        //   multiple: false,
        //   required: false,
        //   width: '100%',
        // },
        { fieldName: 'content', type: 'editor', name: '详细信息', value: '', required: false, width: '100%' },
        { fieldName: 'id', type: 'text', name: 'id', value: '', hide: true },
      ],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      title: '',
    };
  },
  methods: {
    handleTableBtnClick(type, element) {
      const lzList = this.$refs.lzList;
      switch (type) {
        case 'edit':
          this.handleDialogForm(element);
          break;
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const response = await this.$baseHttp.delete(`/help/article/delete?ids=${element.id}`);
              if (!response) return;
              this.$message.success('删除成功');
              lzList.getData();
            },
          });
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    async handleCommonBtnCLick(type) {
      switch (type) {
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          params.class_id = 0;
          const response = await this.$baseHttp.post('/help/article/save', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.handleDialogForm();
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm(response = null) {
      this.title = `${response ? '编辑' : '新增'}${this.$route.meta.title}`;
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        const params = {};
        if (response) {
          params.url = `/help/article/detail?id=${response.id}`;
        } else {
          params.data = {};
        }
        this.$refs.lzForm.setValue(params);
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
